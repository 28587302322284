body {
  background-color: #fff;
}

Link:link, a:link {
  color: #fff;
}

Link:hover, a:hover {
  color: #5d5d5d;
}

Link:visited, a:visited {
  color: #5d5dec;
}

ul {
  list-style: none;
}